import { createStore as reduxCreateStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

const reducer = (state, action) => {
  if (action.type === 'LOADING_START') {
    return { ...state, isLoading: true }
  }
  if (action.type === 'LOADING_END') {
    return { ...state, isLoading: false }
  }
  if (action.type === 'LEAD_SUBMITTED') {
    return { ...state, isLoading: false, leadsubmitted: true }
  }
  if (action.type === 'UNSUB_SUBMITTED') {
    return { ...state, isLoading: false, unsubsubmitted: true }
  }
  if (action.type === 'CAMPAIGNID_UPDATE') {
    return { ...state, campaignid: action.data }
  }

  return state
}

const initialState = {
  isLoading: false,
  leadsubmitted: false,
}

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk))

export default createStore
